import React, { useState } from 'react';
import './App.css';
import ad_1 from './img/1.png'
// import ad_2 from './img/2.png'
import ad_3 from './img/3.png'
import ad_4 from './img/4.png'
import ad_5 from './img/test_img.jpeg'
import video_1 from './img/video_1.mp4'
import Modal from './components/Modal';
import ad_9 from './img/Website.png'
import ad_10 from './img/Banners.gif'
import advert_2 from './img/Advert5_NationWideExpress.png'
import JSEASAdvert from './img/JSEAS-advert.png'

function App() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');
  const [website, setWebsite] = useState('');
  const [mail, setMail] = useState('');
  const [facebook, setFacebook] = useState('');
  const [call, setCall] = useState('');
  const [mediaType, setMediaType] = useState('');

  const handleClickAd1=()=>{
    const url ='https://main.monagis.com/wp-content/uploads/2023/09/MGIPortfolio_2023_V2.pdf'
    window.open(url,'_blank')
  }
  const handleClickAd2=()=>{
    const url ='https://jseas.monagis.com'
    // const url ='https://nationwidecitytocity.com'
    window.open(url,'_blank')
  }
  return (
    <div className='grid grid-cols-2 w-screen h-screen'>
      {/* Top row for ads */}
      <div id = 'test' className='col-span-2 flex'>
        {/* Add your top ads here */}
        {/* Example: */}
        <div className='flex flex-col w-1/2 '>
        <button className=' hover:opacity-20' onClick={handleClickAd1}>
            <img className='test'src={ad_9} />
          </button>
        </div>
        <div className='flex flex-col w-1/2 '>
        {/* <button className=' hover:opacity-20' onClick={handleClickAd2}>
            <img src={advert_2} />
          </button> */}
          <button className=' hover:opacity-20' onClick={handleClickAd2}>
            <img src={JSEASAdvert} />
          </button>
        </div>
      </div>
    <div className='grid grid-cols-12 w-screen h-screen'>
      <div className='col-span-12'>
        <iframe className=' w-full h-full' src="https://panda-gis.maps.arcgis.com/apps/opsdashboard/index.html#/d9e4378d672f491780c8569a56be346d"></iframe>
      </div>
      {/* <div className=' col-span-3 overflow-y-auto'>
        <div className=' flex flex-col'>
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('GIS Software '); setMedia(ad_1); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_1} />
          </button>
          <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Drone Services '); setMedia(video_1); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("video") }}>
            <video src={video_1} autoPlay muted loop />
          </button>
          <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Technical Services '); setMedia(ad_3); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_3} />
          </button>
          <hr className=" h-3 bg-black/80" />
          <button className=' hover:opacity-20' onClick={() => { setShow(true); setTitle('Geospatial Training '); setMedia(ad_4); setWebsite("https://www.main.monagis.com"); setMail("mailto:info@monagis.com"); setFacebook("https://www.facebook.com/mgimona/"); setCall("tel:+18766319694"); setMediaType("image") }}>
            <img src={ad_4} />
          </button>
          <hr className=" h-3 bg-black/80" />
        </div>
        {show && <Modal setShow={setShow} title={title} media={media} website={website} mail={mail} facebook={facebook} call={call} mediaType={mediaType} />}
      </div> */}
    </div >
    </div>
  );
}

export default App;
